import {FC, ReactNode} from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {styled} from '@mui/material/styles';

import Logo from '@/components/Logo';

interface BaseLayoutProps {
  children?: ReactNode;
}

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  ({theme}) => `
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(6)};
`
);

const BlankLayout: FC<BaseLayoutProps> = ({children}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%',
      }}
    >
      <MainContent>
        <TopWrapper>
          <Container maxWidth='md'>
            <Box textAlign='center'>
              <Logo width={200} height={120} />
              {children}
            </Box>
          </Container>
        </TopWrapper>
      </MainContent>
    </Box>
  );
};

export default BlankLayout;
