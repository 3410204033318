import React from 'react';

import Head from 'next/head';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import BlankLayout from 'src/layouts/BlankLayout';

import {useTranslation} from 'react-i18next';

function Status404() {
  const {t}: {t: any} = useTranslation();

  return (
    <>
      <Head>
        <title>Status - 404</title>
      </Head>
      <Box>
        <Typography variant='h2' sx={{my: 1}}>
          {t("The page you were looking for doesn't exist.")}
        </Typography>
        <Typography variant='h4' color='text.secondary' fontWeight='normal' sx={{mb: 4}}>
          {t("It's on us, we moved the content to a different page.")}
        </Typography>
      </Box>
      <Box>
        <Button href='/' variant='outlined'>
          {t('Go to homepage')}
        </Button>
      </Box>
    </>
  );
}

export default Status404;

Status404.getLayout = (page: React.ReactElement) => <BlankLayout>{page}</BlankLayout>;
